import { selector } from "recoil";
import { accessTokenState, userRoleState } from "./auth";
import { utilFetchWithToken } from "../util";
import { ChatSessionType } from "../types/chat-session";
import {
  PublishedContent,
  PublishedContentFindManyResponse,
} from "../types/published-content";
import { UserRoleType } from "../types/user";

export const activityPublishedContentState = selector<PublishedContent[]>({
  key: "activityPublishedContentState",
  get: async ({ get }) => {
    const allContent = get(allPublishedContentState);
    return allContent.filter(
      (content) => content.type === ChatSessionType.ACTIVITY
    );
  },
});

export const storyPublishedContentState = selector<PublishedContent[]>({
  key: "storyPublishedContentState",
  get: async ({ get }) => {
    const allContent = get(allPublishedContentState);
    return allContent.filter(
      (content) => content.type === ChatSessionType.STORY
    );
  },
});

export const songPublishedContentState = selector<PublishedContent[]>({
  key: "songPublishedContentState",
  get: async ({ get }) => {
    const allContent = get(allPublishedContentState);
    return allContent.filter(
      (content) => content.type === ChatSessionType.SONG
    );
  },
});

export const coloringSheetPublishedContentState = selector<PublishedContent[]>({
  key: "coloringSheetPublishedContentState",
  get: async ({ get }) => {
    const allContent = get(allPublishedContentState);
    return allContent.filter(
      (content) => content.type === ChatSessionType.COLORING_SHEET
    );
  },
});

export const imagePublishedContentState = selector<PublishedContent[]>({
  key: "imagePublishedContentState",
  get: async ({ get }) => {
    const allContent = get(allPublishedContentState);
    return allContent.filter(
      (content) => content.type === ChatSessionType.IMAGE
    );
  },
});

export const allPublishedContentState = selector<PublishedContent[]>({
  key: "allPublishedContentState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const userRole = get(userRoleState);

    if (accessToken && userRole?.type !== UserRoleType.SUPER_ADMIN) {
      const response = (await utilFetchWithToken({
        accessToken,
        url: `/published-content`,
        method: "GET",
      })) as PublishedContentFindManyResponse | undefined;

      return response?.publishedContent ?? [];
    }

    return [];
  },
});
