/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import { message, Typography } from "antd";
import TextInterface, { ChatInterfaceProps } from "./text-interface";
import { useAuth } from "../../../context/auth-context";
import SpinnerComponent from "../../common/spinner";
import { useParams } from "react-router-dom";
import {
  ChatSessionGetHistoryResponse,
  ChatSessionMessage,
  ChatSessionMessageType,
} from "../../../types/chat-session";
import { useRecoilValue } from "recoil";
import {
  activityPublishedContentState,
  allPublishedContentState,
} from "../../../states/published-content";
import { PublishedContent } from "../../../types/published-content";

export interface CreateEditTextInterfaceProps
  extends Pick<
    ChatInterfaceProps,
    "title" | "subtitle" | "type" | "buttonTitle" | "titlePlaceholderText"
  > {}

const EditTextInterface: React.FC<CreateEditTextInterfaceProps> = ({
  title,
  subtitle,
  type,
  buttonTitle,
  titlePlaceholderText,
}) => {
  const { publishedContentId } = useParams<{ publishedContentId: string }>();
  const [messageApi, contextHolder] = message.useMessage();
  const { fetchWithToken } = useAuth();
  const allPublishedContent = useRecoilValue(allPublishedContentState);
  const [publishedContentNotFound, setPublishedContentNotFound] =
    useState(false);
  const [sessionId, setSessionId] = useState<number | undefined>();
  const [publishedContent, setPublishedContent] = useState<
    PublishedContent | undefined
  >();

  const [chatSessionHistory, setChatSessionHistory] = useState<
    ChatSessionMessage[]
  >([]);

  useEffect(() => {
    const publishedContent = allPublishedContent.find(
      (content) => content.id === Number(publishedContentId)
    );

    if (!publishedContent) {
      setPublishedContentNotFound(true);
      return;
    }

    setSessionId(publishedContent.sessionId);
    setPublishedContent(publishedContent);
  }, [allPublishedContent, publishedContentId]);

  const refreshSessionHistory = useCallback(async () => {
    if (sessionId !== undefined) {
      const sessionWithHistoryResponse = (await fetchWithToken({
        url: `/chat-session/${sessionId}/history`,
        method: "GET",
      })) as ChatSessionGetHistoryResponse;

      setChatSessionHistory(sessionWithHistoryResponse.history);
    }
  }, [fetchWithToken, sessionId]);

  useEffect(() => {
    refreshSessionHistory();
  }, [refreshSessionHistory, sessionId]);

  if (publishedContentNotFound || sessionId === undefined) {
    return <SpinnerComponent />;
  }

  return (
    <>
      {contextHolder}
      <TextInterface
        sessionId={sessionId}
        title={title}
        subtitle={subtitle}
        type={type}
        buttonTitle={buttonTitle}
        titlePlaceholderText={titlePlaceholderText}
        sessionMessageHistory={chatSessionHistory}
        handleRefreshSessionHistory={refreshSessionHistory}
        publishedContent={publishedContent}
      />
    </>
  );
};

export default EditTextInterface;
