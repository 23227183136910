/** @jsxImportSource @emotion/react */
import styled from "styled-components";
import { Users } from "lucide-react";
import { CheckboxGroupProps } from "antd/es/checkbox";
import { Flex, Radio, RadioChangeEvent } from "antd";

export enum AgeRange {
  TWO_THREE = "2-3",
  THREE_FOUR = "3-4",
  FOUR_FIVE = "4-5",
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const Icon = styled(Users)`
  height: 1rem;
  width: 1rem;
  color: #3b82f6;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

interface AgeButtonProps {
  active: boolean;
}

const AgeButton = styled.button<AgeButtonProps>`
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${(props) => (props.active ? "#2563eb" : "white")};
  color: ${(props) => (props.active ? "white" : "inherit")};

  &:hover {
    background-color: ${(props) => (props.active ? "#2563eb" : "#f3f4f6")};
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;

interface AgeSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

export function AgeSelector({ value, onChange }: AgeSelectorProps) {
  const options: CheckboxGroupProps<string>["options"] = [
    { value: AgeRange.TWO_THREE, label: "2-3 yrs" },
    { value: AgeRange.THREE_FOUR, label: "3-4 yrs" },
    { value: AgeRange.FOUR_FIVE, label: "4-5 yrs" },
  ];

  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
  };

  return (
    <Flex vertical gap={8}>
      <Title>Age Range</Title>
      <Container>
        <Icon />
        <ButtonGroup>
          <Radio.Group
            value={value}
            options={options}
            optionType="button"
            size="small"
            onChange={handleChange}
          />
        </ButtonGroup>
      </Container>
    </Flex>
  );
}
