/** @jsxImportSource @emotion/react */

import { Button, Dropdown, Typography } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  currentUserNameWithGreetingState,
  userRoleState,
} from "../../states/auth";
import styled from "styled-components";
import { schoolState } from "../../states/school";
import { classroomsState } from "../../states/classrooms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faGear } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { studentsState } from "../../states/student";
import { css } from "@emotion/react";
import { useAuth } from "../../context/auth-context";
import { UserRoleType } from "../../types/user";
import {
  activityPublishedContentState,
  coloringSheetPublishedContentState,
  songPublishedContentState,
  storyPublishedContentState,
  imagePublishedContentState,
} from "../../states/published-content";

const Mascot = styled.img`
  height: 35px;
  width: auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const NavText = styled.span`
  cursor: pointer;
`;

const BaseNavComponent = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <LogoContainer onClick={() => navigate("/")}>
        <Mascot
          src={`${process.env.PUBLIC_URL}/icon.png`}
          alt="Clear Day Mascot"
        />
        <Typography.Title level={4}>Clear Day</Typography.Title>
      </LogoContainer>
      {children}
    </Container>
  );
};

const TopNavComponent = () => {
  const school = useRecoilValue(schoolState);
  const classrooms = useRecoilValue(classroomsState);
  const allStudents = useRecoilValue(studentsState);
  const activities = useRecoilValue(activityPublishedContentState);
  const stories = useRecoilValue(storyPublishedContentState);
  const songs = useRecoilValue(songPublishedContentState);
  const coloringSheets = useRecoilValue(coloringSheetPublishedContentState);
  const images = useRecoilValue(imagePublishedContentState);

  const navigate = useNavigate();
  const location = useLocation();
  const greeting = useRecoilValue(currentUserNameWithGreetingState);
  const { classroomId, studentId, publishedContentId } = useParams();
  const { handleUserLogout } = useAuth();
  const userRole = useRecoilValue(userRoleState);

  const selectedClassroom =
    classroomId !== undefined
      ? classrooms.find((classroom) => classroom.id === Number(classroomId))
      : undefined;

  const selectedStudent =
    studentId !== undefined
      ? allStudents.find((student) => student.id === Number(studentId))
      : undefined;

  const selectedActivity =
    publishedContentId !== undefined
      ? activities.find(
          (activity) => activity.id === Number(publishedContentId)
        )
      : undefined;

  const selectedStory =
    publishedContentId !== undefined
      ? stories.find((story) => story.id === Number(publishedContentId))
      : undefined;

  const selectedSong =
    publishedContentId !== undefined
      ? songs.find((song) => song.id === Number(publishedContentId))
      : undefined;

  const selectedColoringSheet =
    publishedContentId !== undefined
      ? coloringSheets.find(
          (coloringSheet) => coloringSheet.id === Number(publishedContentId)
        )
      : undefined;

  const selectedImage =
    publishedContentId !== undefined
      ? images.find((image) => image.id === Number(publishedContentId))
      : undefined;

  const allToolsLink = (
    <NavText>
      <Typography.Title level={5} onClick={() => navigate("/tools")}>
        Tools
      </Typography.Title>
    </NavText>
  );

  // Chat
  const chatLink = (
    <>
      {allToolsLink}
      <FontAwesomeIcon icon={faCaretRight} />
      <NavText>
        <Typography.Title level={5} onClick={() => navigate("/tools/chat")}>
          Sunny Chat
        </Typography.Title>
      </NavText>
    </>
  );

  // Activities

  const newActivityLink = (
    <NavText>
      <Typography.Title
        level={5}
        onClick={() => navigate("/tools/activity/new")}
      >
        New Activity
      </Typography.Title>
    </NavText>
  );

  const activityTitleLink = selectedActivity ? (
    <NavText>
      <Typography.Title
        level={5}
        ellipsis={{ tooltip: selectedActivity.title }}
        onClick={() => navigate(`/tools/activity/${selectedActivity.id}/edit`)}
      >
        {selectedActivity.title}
      </Typography.Title>
    </NavText>
  ) : null;

  // Stories
  const newStoryLink = (
    <NavText>
      <Typography.Title level={5} onClick={() => navigate("/tools/story/new")}>
        New Story
      </Typography.Title>
    </NavText>
  );

  const storyTitleLink = selectedStory ? (
    <NavText>
      <Typography.Title
        level={5}
        ellipsis={{ tooltip: selectedStory.title }}
        onClick={() => navigate(`/tools/story/${selectedStory.id}`)}
      >
        {selectedStory.title}
      </Typography.Title>
    </NavText>
  ) : null;

  // Songs
  const newSongLink = (
    <NavText>
      <Typography.Title level={5} onClick={() => navigate("/tools/song/new")}>
        New Song
      </Typography.Title>
    </NavText>
  );

  const songTitleLink = selectedSong ? (
    <NavText>
      <Typography.Title
        level={5}
        ellipsis={{ tooltip: selectedSong.title }}
        onClick={() => navigate(`/tools/song/${selectedSong.id}`)}
      >
        {selectedSong.title}
      </Typography.Title>
    </NavText>
  ) : null;

  // Coloring Sheets
  const newColoringSheetLink = (
    <NavText>
      <Typography.Title
        level={5}
        onClick={() => navigate("/tools/coloring-sheet/new")}
      >
        New Coloring Sheet
      </Typography.Title>
    </NavText>
  );

  const coloringSheetTitleLink = selectedColoringSheet ? (
    <NavText>
      <Typography.Title
        level={5}
        ellipsis={{ tooltip: selectedColoringSheet?.title }}
        onClick={() =>
          navigate(`/tools/coloring-sheet/${selectedColoringSheet?.id}`)
        }
      >
        {selectedColoringSheet?.title}
      </Typography.Title>
    </NavText>
  ) : null;

  // Images
  const newImageLink = (
    <NavText>
      <Typography.Title level={5} onClick={() => navigate("/tools/image/new")}>
        New Image
      </Typography.Title>
    </NavText>
  );

  const HomeLink = (
    <>
      {userRole?.type !== UserRoleType.TOOLS_ONLY && (
        <NavText>
          <Typography.Title level={5} onClick={() => navigate("/")}>
            {userRole?.type === UserRoleType.SUPER_ADMIN
              ? "SUPER ADMIN"
              : school?.name}
          </Typography.Title>
        </NavText>
      )}
    </>
  );

  const classroomLink = (
    <NavText>
      <Typography.Title
        level={5}
        onClick={() => navigate(`/classroom/${classroomId}`)}
      >
        {selectedClassroom?.name}
      </Typography.Title>
    </NavText>
  );

  const studentLink = (
    <NavText>
      <Typography.Title
        level={5}
        onClick={() => navigate(`/student/${selectedStudent?.id}`)}
      >
        {selectedStudent?.firstName} {selectedStudent?.lastName}
      </Typography.Title>
    </NavText>
  );

  const settingsButton = (
    <div
      css={css`
        margin-left: auto;
        margin-right: 135px;

        @media (max-width: 768px) {
          margin-right: 0;
        }
      `}
    >
      <Dropdown
        menu={{
          items: [
            {
              label: (
                <div onClick={() => navigate("/settings/user")}>Settings</div>
              ),
              key: "settings",
            },
            {
              label: <div onClick={() => handleUserLogout()}>Log Out</div>,
              key: "logout",
            },
          ],
        }}
      >
        <Button icon={<FontAwesomeIcon icon={faGear} />} />
      </Dropdown>
    </div>
  );

  if (location.pathname === "/" || location.pathname === "/tools") {
    return (
      <BaseNavComponent>
        <Typography.Title level={5}>{greeting}</Typography.Title>
        {settingsButton}
      </BaseNavComponent>
    );
  }

  if (location.pathname === "/tools/activity/new") {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {newActivityLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/activity/") && selectedActivity) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {activityTitleLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/story/new")) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {newStoryLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/story/") && selectedStory) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {storyTitleLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/song/new")) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {newSongLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/song/") && selectedSong) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {songTitleLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/coloring-sheet/new")) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {newColoringSheetLink}
      </BaseNavComponent>
    );
  }

  if (
    location.pathname.startsWith("/tools/coloring-sheet/") &&
    selectedColoringSheet
  ) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {coloringSheetTitleLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/chat")) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {chatLink}
      </BaseNavComponent>
    );
  }

  if (location.pathname.startsWith("/tools/image/new")) {
    return (
      <BaseNavComponent>
        {HomeLink}
        {allToolsLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {newImageLink}
      </BaseNavComponent>
    );
  }

  if (selectedClassroom !== undefined && selectedStudent === undefined) {
    return (
      <BaseNavComponent>
        {HomeLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {classroomLink}
      </BaseNavComponent>
    );
  }

  if (selectedClassroom !== undefined && selectedStudent !== undefined) {
    return (
      <BaseNavComponent>
        {HomeLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {classroomLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {studentLink}
      </BaseNavComponent>
    );
  }

  if (selectedStudent !== undefined) {
    return (
      <BaseNavComponent>
        {HomeLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {studentLink}
      </BaseNavComponent>
    );
  }

  return <BaseNavComponent>{HomeLink}</BaseNavComponent>;
};
export default TopNavComponent;
