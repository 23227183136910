/** @jsxImportSource @emotion/react */
import React, { useState, KeyboardEvent, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Collapse,
  Flex,
  Image,
  Input,
  message,
  Modal,
  Spin,
  Tag,
  Typography,
} from "antd";
import styled from "styled-components";
import { css } from "@emotion/react";
import { useAuth } from "../../../context/auth-context";
import { ChatRequest } from "../../../types/chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faFloppyDisk,
  faPencil,
  faPrint,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  ChatSessionMessage,
  ChatSessionType,
} from "../../../types/chat-session";
import {
  PublishedContent,
  PublishedContentColoringSheetCreateRequest,
  PublishedContentCreateRequest,
} from "../../../types/published-content";
import SpinnerComponent from "../../common/spinner";
import Divider from "../../common/divider";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Bot,
  Lightbulb,
  Loader,
  LoaderCircle,
  MessageCircle,
  Printer,
  Save,
  Send,
  Sparkles,
} from "lucide-react";
import { AgeSelector, AgeRange } from "./age-selector";
import { DurationSelector } from "./duration-selector";
import { ActivityTags } from "./focus-area-tags";
import { motion } from "framer-motion";
import Spinner from "../../common/spinner";

const Container = styled.div`
  width: 100%;
`;

const ChatContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 16px;
  }
`;

const InputContainer = styled.div`
  flex: 1;
`;

const ResponseContainerWrapper = styled.div`
  flex: 1;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const ResponseContainer = styled.div`
  padding: 16px;
  background-color: #f0f2f5;
  border-radius: 4px;
  font-size: 14px;
`;

const ContextControlsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0;
  flex-direction: column;
`;

const VersionHistoryCard = styled(Card)`
  width: 100%;

  .ant-card-head {
    padding: 0 16px;
  }

  .ant-card-body {
    padding: 8px 16px;
  }

  .ant-card-head,
  .ant-card-body,
  .ant-card-body .ant-typography {
    color: #4b5563 !important;
  }

  background-color: rgba(239, 246, 255, 0.5);
`;

/**
 *
 * V2 Results Panel
 */

// Results Panel Styled Components
const ResultsPanel = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e9d5ff;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultsHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #e9d5ff;
  background: linear-gradient(to right, #f5f3ff, #fce7f3);
`;

const ResultsTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  color: #6d28d9;
`;

const ResultsSubtitle = styled.p`
  font-size: 0.875rem;
  color: #9333ea;
`;

const ResultsContent = styled.div`
  flex-grow: 1;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 1.5rem;
`;

const EmptyIcon = styled.div`
  width: 6rem;
  height: 6rem;
  margin: 0 auto 1rem;
  opacity: 0.2;
  color: #c4b5fd;
`;

const EmptyText = styled.p`
  color: #6b7280;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const LoadingSpinner = styled.div`
  position: relative;
  width: 5rem;
  height: 5rem;
`;

const SpinnerRing = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid;
  border-color: #9333ea #ec4899 #f59e0b #60a5fa;
`;

const SpinnerCore = styled.div`
  position: absolute;
  inset: 0.5rem;
  border-radius: 50%;
  background: linear-gradient(to bottom right, #c4b5fd, #f9a8d4);
  opacity: 0.5;
`;

const LoadingMessage = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  color: #8b5cf6;
`;

const ResultContent = styled.div`
  width: 100%;
`;

const TextResult = styled.div`
  background-color: rgba(245, 243, 255, 0.3);
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #e9d5ff;
`;

const ResultText = styled.p`
  color: #4b5563;
  line-height: 1.6;
`;

const ResultImage = styled.img`
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
`;

const ActionBar = styled.div`
  padding: 1rem;
  border-top: 1px solid #e9d5ff;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

interface ActionButtonProps {
  primary?: boolean;
  disabled?: boolean;
}

const ActionButton = styled.button<ActionButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const PrimaryActionButton = styled(ActionButton)`
  background: linear-gradient(to right, #9333ea, #ec4899);
  color: white;
  border: none;

  &:hover:not(:disabled) {
    background: linear-gradient(to right, #7e22ce, #db2777);
  }
`;

const SecondaryActionButton = styled(ActionButton)`
  background-color: white;
  color: #6b7280;
  border: 1px solid #e5e7eb;

  &:hover:not(:disabled) {
    background-color: #f9fafb;
  }
`;

const loadingMessages = [
  "Sprinkling magic dust on your request...",
  "Asking the alphabet monsters for help...",
  "Coloring outside the lines...",
  "Counting to infinity by twos...",
  "Searching for unicorns and rainbows...",
  "Mixing primary colors to make new ones...",
  "Gathering stars from the sky...",
  "Waking up the sleepy crayons...",
  "Consulting with the wise old owl...",
  "Playing hopscotch with your ideas...",
];

const REQUEST_URL = {
  [ChatSessionType.ACTIVITY]: "activity",
  [ChatSessionType.CHAT]: "",
  [ChatSessionType.STUDENT_EVENT]: "student-event",
  [ChatSessionType.STORY]: "story",
  [ChatSessionType.SONG]: "song",
  [ChatSessionType.COLORING_SHEET]: "coloring-sheet",
  [ChatSessionType.IMAGE]: "image",
};

export interface ChatInterfaceProps {
  title: string;
  subtitle: string;
  type: ChatSessionType;
  buttonTitle: string;
  titlePlaceholderText?: string;
  sessionId: number;
  sessionMessageHistory: ChatSessionMessage[];
  handleRefreshSessionHistory: () => void;
  publishedContent?: PublishedContent;
}

const TextInterface: React.FC<ChatInterfaceProps> = ({
  title,
  subtitle,
  type,
  buttonTitle,
  titlePlaceholderText,
  sessionId,
  sessionMessageHistory,
  handleRefreshSessionHistory,
  publishedContent,
}) => {
  const navigate = useNavigate();
  const { fetchWithToken } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const [requestLoading, setRequestLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [requestText, setRequestText] = useState("");
  // Used for streaming text responses
  const [responseText, setResponseText] = useState("");
  // Used for responses with attachments
  const [responseUrl, setResponseUrl] = useState("");
  const [responseAttachmentId, setResponseAttachmentId] = useState<
    number | undefined
  >();
  const [responseTitle, setResponseTitle] = useState("");
  const [responseSummary, setResponseSummary] = useState("");

  const [error, setError] = useState(false);
  const [editing, setEditing] = useState(false);

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [ageRange, setAgeRange] = useState(AgeRange.THREE_FOUR);
  const [duration, setDuration] = useState(15);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (requestLoading) {
      const interval = setInterval(() => {
        setLoadingMessage(
          loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
        );
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [requestLoading]);

  const generateContentAsync = async (requestText: string) => {
    try {
      setRequestLoading(true);
      setLoadingMessage(loadingMessages[0]);
      setResponseText("");
      setError(false);

      if (!sessionId) {
        messageApi.error({
          content: "No session found. Please refresh the page",
        });
        return;
      }

      if (
        type !== ChatSessionType.COLORING_SHEET &&
        type !== ChatSessionType.IMAGE
      ) {
        const requirements = `Requirements: Age Range - ${ageRange}, Duration - ${duration} minutes, Tags - ${selectedTags.join(
          ", "
        )}`;
        requestText += `\n\n${requirements}`;
      }

      const requestBody: ChatRequest = {
        requestText,
        sessionId,
      };

      const response = await fetchWithToken({
        url: `/chat/${REQUEST_URL[type]}`,
        method: "POST",
        body: requestBody,
        stream: true,
      });

      if (
        type !== ChatSessionType.COLORING_SHEET &&
        type !== ChatSessionType.IMAGE
      ) {
        if (response.body) {
          const reader = response.body.getReader();
          let chunks = "";

          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            chunks += new TextDecoder("utf-8").decode(value);
            setResponseText(chunks);
          }
        }

        setRequestText("");
      } else {
        setResponseUrl(response.imageUrl);
        setResponseAttachmentId(response.attachmentId);
      }
      handleRefreshSessionHistory();
    } catch (error) {
      messageApi.error({ content: "Error creating" });
      console.error(error);
      setError(true);
    }

    setRequestLoading(false);
    setRequestText("");
  };

  const generateContent = async (requestText: string) => {
    try {
      setRequestLoading(true);
      setLoadingMessage(loadingMessages[0]);
      setResponseText("");
      setError(false);

      if (!sessionId) {
        messageApi.error({
          content: "No session found. Please refresh the page",
        });
        return;
      }

      if (requestText === "") {
        requestText = "Requirements have changed.";
      }

      if (
        type !== ChatSessionType.COLORING_SHEET &&
        type !== ChatSessionType.IMAGE
      ) {
        const requirements = `
        Requirements:
        - Age group: Appropriate for children aged ${ageRange} years 
        ${
          type === ChatSessionType.STORY || type === ChatSessionType.ACTIVITY
            ? `- Design the ${buttonTitle.toLowerCase()} to fit within a timeframe of ${duration} minutes`
            : ""
        }
        ${
          type === ChatSessionType.ACTIVITY && selectedTags.length > 0
            ? `- Focus areas:\n${selectedTags
                .map((tag) => `    - ${tag}`)
                .join("\n")}`
            : ""
        }`;

        requestText += `\n${requirements}`;
      }

      const requestBody: ChatRequest = {
        requestText,
        sessionId,
      };

      const response = await fetchWithToken({
        url: `/chat/${REQUEST_URL[type]}`,
        method: "POST",
        body: requestBody,
      });

      if (
        type !== ChatSessionType.COLORING_SHEET &&
        type !== ChatSessionType.IMAGE
      ) {
        if (typeof response === "string") {
          setResponseText(response);
        } else if (response && typeof response === "object" && response.title) {
          setResponseTitle(response.title);
          setResponseText(response.content);
          setResponseSummary(response.summary);
        }

        setRequestText("");
      } else {
        setResponseUrl(response.imageUrl);
        setResponseAttachmentId(response.attachmentId);
      }
      handleRefreshSessionHistory();
    } catch (error) {
      messageApi.error({ content: "Error creating" });
      console.error(error);
      setError(true);
    }

    setRequestLoading(false);
    setRequestText("");
  };

  const saveContent = async (updateExisting?: boolean) => {
    if (responseTitle === "" && !updateExisting) {
      messageApi.error({ content: "A title is required" });
      return;
    }

    if (!sessionId) {
      messageApi.error({
        content: "No session found. Please refresh the page",
      });
      return;
    }

    try {
      setSaveModalOpen(false);
      setSaveLoading(true);

      const requestBody: PublishedContentCreateRequest = {
        title: responseTitle,
        text: responseText,
        summary: responseSummary,
        type,
        sessionId,
      };

      let saveContentResponse: PublishedContent | undefined;

      if (!updateExisting || !publishedContent) {
        saveContentResponse = (await fetchWithToken({
          url: `/published-content`,
          method: "POST",
          body: {
            ...requestBody,
            text: responseText !== "" ? responseText : publishedContent?.text,
          },
        })) as PublishedContent | undefined;
      }

      if (updateExisting && publishedContent) {
        saveContentResponse = (await fetchWithToken({
          url: `/published-content/${publishedContent.id}`,
          method: "PUT",
          body: {
            text: requestBody.text,
          },
        })) as PublishedContent | undefined;
      }

      if (saveContentResponse) {
        messageApi.open({
          type: "success",
          content: "Saved successfully",
        });

        navigate(`/tools/${REQUEST_URL[type]}`);
      }
    } catch (error) {
      messageApi.error({ content: "Error saving" });
      console.log(error);
    }

    setSaveLoading(false);
  };

  const saveImage = async () => {
    if (responseAttachmentId === undefined) {
      messageApi.error({ content: "No image found" });
      return;
    }

    if (!sessionId) {
      messageApi.error({
        content: "No session found. Please refresh the page",
      });
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Working our magic...",
      key: "loadingMessage",
      duration: 0,
    });

    try {
      const requestBody: PublishedContentColoringSheetCreateRequest = {
        attachmentId: responseAttachmentId,
        sessionId,
        title: responseTitle,
      };

      const saveContentResponse = await fetchWithToken({
        url: `/published-content/${
          type === ChatSessionType.COLORING_SHEET ? "coloring-sheet" : "image"
        }`,
        method: "POST",
        body: requestBody,
      });

      if (saveContentResponse) {
        messageApi.open({
          type: "success",
          content: "Saved successfully",
        });

        navigate(`/tools/${REQUEST_URL[type]}`);
      }
    } catch (error) {
      messageApi.error({ content: "Error saving" });
      console.log(error);
    }
  };

  const handlePrint = () => {
    const attachmentUrlToPrint =
      responseUrl !== "" ? responseUrl : publishedContent?.attachmentUrl;

    if (!attachmentUrlToPrint && responseText !== "") {
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        // Write the content to the new window
        printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>
              <pre>${responseTextToShow}</pre>
            </body>
          </html>
        `);
        // Close the document to ensure all content is loaded
        printWindow.document.close();
        // Wait for the content to load before printing
        setTimeout(() => {
          printWindow.print();
          printWindow.close();
        }, 500);
      }
    }

    if (attachmentUrlToPrint) {
      const printWindow = window.open(attachmentUrlToPrint, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    }
  };

  const responseTextToShow =
    responseText !== "" ? responseText : publishedContent?.text;

  const versionHistory = sessionMessageHistory
    .map((history, index) => {
      if (history.type !== "ASSISTANT") return null;

      let systemResponseChanges = "";
      let systemResponseVersion = 1;
      let userMessage = "";

      try {
        const parsedMessage = JSON.parse(history.message);
        systemResponseChanges = parsedMessage.changes || "";
        systemResponseVersion = parsedMessage.version || 1;
      } catch (e) {
        console.error("Failed to parse message:", e);
        console.log(history);
      }

      // Find the user message that precedes the current system message
      if (index > 0 && sessionMessageHistory[index - 1].type === "USER") {
        userMessage = sessionMessageHistory[index - 1].message;
      }

      const isLatestVersion = index === sessionMessageHistory.length - 1;

      return (
        <Flex>
          <VersionHistoryCard
            key={index}
            title={
              <Flex justify="space-between" align="center">
                <div>{`Version ${systemResponseVersion}`}</div>
                <Tag color={isLatestVersion ? "green" : "grey"}>
                  {isLatestVersion ? "Latest" : "Older"}
                </Tag>
              </Flex>
            }
          >
            <Flex vertical gap={12}>
              <Flex gap={12}>
                <MessageCircle style={{ width: "16px" }} />
                <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
                  {userMessage}
                </Typography.Paragraph>
              </Flex>
              {systemResponseVersion > 1 && (
                <Flex gap={12}>
                  <Bot />
                  <Typography.Paragraph>
                    {systemResponseChanges}
                  </Typography.Paragraph>
                </Flex>
              )}
            </Flex>
          </VersionHistoryCard>
        </Flex>
      );
    })
    .filter((element) => element !== null)
    .reverse();

  const titleToShow =
    responseTitle !== "" ? responseTitle : publishedContent?.title;
  const summaryToShow =
    responseSummary !== "" ? responseSummary : publishedContent?.summary;
  const attachmentUrlToShow =
    responseUrl !== "" ? responseUrl : publishedContent?.attachmentUrl;

  return (
    <Container>
      {contextHolder}
      <Flex vertical gap={16} align="start">
        <ChatContainer>
          <InputContainer>
            <Card>
              {error && (
                <Alert
                  message="We couldn't process your request. Please try again."
                  type="error"
                  showIcon
                  style={{ marginBottom: 16 }}
                />
              )}

              <Flex align="center" gap={8} style={{ marginBottom: 16 }}>
                <Lightbulb style={{ color: "#eab308" }} />
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Describe your {buttonTitle.toLowerCase()}
                </Typography.Title>
              </Flex>
              <Input.TextArea
                value={requestText}
                rows={4}
                onChange={(event) => setRequestText(event.target.value)}
                placeholder="What would you like to create?"
                onFocus={(e) =>
                  (e.currentTarget.style.border = "1px solid #5a3fdc")
                }
                onBlur={(e) => (e.currentTarget.style.border = "")}
              />

              <ContextControlsContainer>
                <Flex gap={24}>
                  <AgeSelector
                    value={ageRange}
                    onChange={(ageRange) => setAgeRange(ageRange as AgeRange)}
                  />
                  {(type === ChatSessionType.ACTIVITY ||
                    type === ChatSessionType.STORY) && (
                    <DurationSelector
                      value={duration}
                      onChange={(duration) => setDuration(duration)}
                    />
                  )}
                </Flex>
                {type === ChatSessionType.ACTIVITY && (
                  <ActivityTags
                    selected={selectedTags}
                    onSelect={setSelectedTags}
                  />
                )}
              </ContextControlsContainer>
              <Button
                type="primary"
                block
                loading={requestLoading}
                onClick={() => generateContent(requestText)}
                style={{
                  background:
                    "linear-gradient(180deg, #7a21db 0%, #5a3fdc 100%)",
                  border: "none",
                  transition: "transform 0.3s ease",
                  marginBottom: 12,
                }}
                icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "translateY(-2px)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "translateY(0)")
                }
              >
                {sessionMessageHistory.length > 0
                  ? `Revise ${buttonTitle}`
                  : `Create ${buttonTitle}`}
              </Button>
              {type !== ChatSessionType.COLORING_SHEET &&
                type !== ChatSessionType.IMAGE &&
                sessionMessageHistory.length > 0 && (
                  <Flex
                    vertical
                    gap={16}
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Typography.Title level={5}>
                      Version History
                    </Typography.Title>
                    {versionHistory}
                  </Flex>
                )}
            </Card>
          </InputContainer>

          <ResultsPanel>
            <ResultsHeader>
              <ResultsTitle>
                {titleToShow ?? "Your Magical Creation"}
              </ResultsTitle>
              <ResultsSubtitle>
                {summaryToShow ??
                  "Watch your ideas transform into learning magic!"}
              </ResultsSubtitle>
            </ResultsHeader>

            <ResultsContent>
              {requestLoading && (
                <LoadingContainer>
                  <Spinner />
                  <motion.div
                    key={loadingMessage}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    <LoadingMessage>{loadingMessage}</LoadingMessage>
                  </motion.div>
                </LoadingContainer>
              )}

              {!requestLoading &&
                !responseTextToShow &&
                !attachmentUrlToShow && (
                  <EmptyState>
                    <EmptyIcon>
                      <Sparkles size="100%" />
                    </EmptyIcon>
                    <EmptyText>
                      Tell us what you want to create and click Create{" "}
                      {buttonTitle}.
                    </EmptyText>
                  </EmptyState>
                )}
              {!requestLoading &&
                attachmentUrlToShow &&
                (type === ChatSessionType.COLORING_SHEET ||
                  type === ChatSessionType.IMAGE) && (
                  <Image
                    style={{ width: "auto", height: "70vh" }}
                    preview={false}
                    src={attachmentUrlToShow}
                  />
                )}
              {!requestLoading &&
                type !== ChatSessionType.COLORING_SHEET &&
                type !== ChatSessionType.IMAGE && (
                  <div
                    css={css`
                      h3 {
                        font-size: 1.17em;
                      }
                      h4 {
                        font-size: 1.07em;
                      }
                    `}
                  >
                    <Markdown remarkPlugins={[remarkGfm]}>
                      {responseTextToShow}
                    </Markdown>
                  </div>
                )}
            </ResultsContent>

            {/* Action buttons */}
            <ActionBar>
              <SecondaryActionButton
                disabled={!responseTextToShow && !attachmentUrlToShow}
                onClick={() => setSaveModalOpen(true)}
              >
                <Save size={16} style={{ marginRight: "4px" }} />{" "}
                {publishedContent ? "Update" : "Save to Library"}
              </SecondaryActionButton>
              <SecondaryActionButton
                disabled={!attachmentUrlToShow && !responseTextToShow}
                onClick={handlePrint}
              >
                <Printer size={16} style={{ marginRight: "4px" }} /> Print
              </SecondaryActionButton>
              {/**
              <PrimaryActionButton primary disabled={!responseTextToShow}>
                <Send size={16} style={{ marginRight: "4px" }} /> Share
              </PrimaryActionButton>
              **/}
            </ActionBar>
          </ResultsPanel>

          {/**
          <ResponseContainerWrapper>
            {(responseTextToShow || responseUrl !== "") && (
              <ActionContainer>
                {type !== ChatSessionType.CHAT && (
                  <ButtonsContainer>
                    {/**
                    {type !== ChatSessionType.COLORING_SHEET &&
                      type !== ChatSessionType.IMAGE && (
                        <Button
                          icon={<FontAwesomeIcon icon={faPencil} />}
                          disabled={requestLoading || saveLoading}
                          onClick={() => setEditing(!editing)}
                        >
                          {editing ? "Done Manually Editing" : "Manually Edit"}
                        </Button>
                      )}
                         */}
          {/**
                    {type !== ChatSessionType.COLORING_SHEET &&
                      type !== ChatSessionType.IMAGE && (
                        <Button
                          icon={<FontAwesomeIcon icon={faFilePen} />}
                          disabled={
                            requestLoading || saveLoading || responseText === ""
                          }
                          type="primary"
                          onClick={() =>
                            publishedContent
                              ? saveContent(true)
                              : setSaveModalOpen(true)
                          }
                        >
                          Publish {buttonTitle} to Library
                        </Button>
                      )}
                    {(type === ChatSessionType.COLORING_SHEET ||
                      type === ChatSessionType.IMAGE) && (
                      <Button
                        icon={<FontAwesomeIcon icon={faFilePen} />}
                        disabled={
                          requestLoading || saveLoading || responseUrl === ""
                        }
                        type="primary"
                        onClick={() => setSaveModalOpen(true)}
                      >
                        Publish {buttonTitle} to Library
                      </Button>
                    )}
                    {publishedContent &&
                      type !== ChatSessionType.COLORING_SHEET &&
                      type !== ChatSessionType.IMAGE && (
                        <Button
                          icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                          disabled={requestLoading || saveLoading}
                          onClick={() => setSaveModalOpen(true)}
                        >
                          Save as New {buttonTitle}
                        </Button>
                      )}
                    {(type === ChatSessionType.COLORING_SHEET ||
                      type === ChatSessionType.IMAGE) && (
                      <Button
                        icon={<FontAwesomeIcon icon={faPrint} />}
                        onClick={handlePrint}
                        disabled={responseUrl === ""}
                      >
                        Print
                      </Button>
                    )}
                  </ButtonsContainer>
                )}
                {type !== ChatSessionType.COLORING_SHEET &&
                  type !== ChatSessionType.IMAGE && (
                    <ResponseContainer>
                      {editing ? (
                        <Input.TextArea
                          rows={6}
                          value={responseTextToShow}
                          onChange={(e) => setResponseText(e.target.value)}
                          onBlur={() => setEditing(false)}
                          autoSize={{ minRows: 6 }}
                        />
                      ) : (
                        <div
                          css={css`
                            h3 {
                              font-size: 1.17em;
                            }
                          `}
                        >
                          <Markdown remarkPlugins={[remarkGfm]}>
                            {responseTextToShow}
                          </Markdown>
                        </div>
                      )}
                    </ResponseContainer>
                  )}
                {(type === ChatSessionType.COLORING_SHEET ||
                  type === ChatSessionType.IMAGE) && (
                  <Image
                    style={{ width: "auto", height: "70vh" }}
                    preview={false}
                    src={responseUrl}
                  />
                )}
              </ActionContainer>
            )}
          </ResponseContainerWrapper>
           */}
        </ChatContainer>
      </Flex>
      <Modal
        open={saveModalOpen}
        onOk={() =>
          type === ChatSessionType.COLORING_SHEET ||
          type === ChatSessionType.IMAGE
            ? saveImage()
            : saveContent()
        }
        onCancel={() => setSaveModalOpen(false)}
        title={`What should we call this ${buttonTitle.toLowerCase()}?`}
      >
        <Flex vertical>
          <Input
            placeholder={titlePlaceholderText}
            value={responseTitle ?? publishedContent?.title}
            onChange={(event) => setResponseTitle(event.currentTarget.value)}
          />
        </Flex>
      </Modal>
    </Container>
  );
};

export default TextInterface;
