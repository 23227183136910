/** @jsxImportSource @emotion/react */
import styled from "styled-components";
import { Clock } from "lucide-react";
import { Flex, Slider } from "antd";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Icon = styled(Clock)`
  height: 1rem;
  width: 1rem;
  color: #f59e0b;
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledSlider = styled(Slider)`
  width: 6rem;
`;

const DurationText = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

interface DurationSelectorProps {
  value: number;
  onChange: (value: number) => void;
}

export function DurationSelector({ value, onChange }: DurationSelectorProps) {
  return (
    <Flex vertical gap={8}>
      <Title>Duration</Title>
      <Container>
        <Icon />
        <SliderContainer>
          <StyledSlider
            range
            value={[value]}
            min={5}
            max={60}
            step={5}
            onChange={(vals) => onChange(vals[0])}
          />
          <DurationText>{value} mins</DurationText>
        </SliderContainer>
      </Container>
    </Flex>
  );
}
