/** @jsxImportSource @emotion/react */
import { Card, Flex, Typography } from "antd";
import { AudioLines, Book, FerrisWheel, ImageIcon } from "lucide-react";
import { Palette } from "lucide-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  margin: 24px 32px 0px;
`;

const StyledCard = styled(Card)`
  width: 300px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const NewToolListing = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Typography.Title level={3}>
        Pick a Tool to Create Something New
      </Typography.Title>
      <Flex gap="large" wrap="wrap">
        <StyledCard
          title={
            <Flex align="center" gap="small">
              <Palette style={{ color: "#87CEEB" }} />
              Coloring Sheets
            </Flex>
          }
          onClick={() => navigate("/tools/coloring-sheet/new")}
        >
          <Typography.Text>
            Create a custom coloring sheet for your students
          </Typography.Text>
        </StyledCard>
        <StyledCard
          title={
            <Flex align="center" gap="small">
              <ImageIcon style={{ color: "#FFB6C1" }} />
              Image
            </Flex>
          }
          onClick={() => navigate("/tools/image/new")}
        >
          <Typography.Text>Create an image for your students</Typography.Text>
        </StyledCard>
        <StyledCard
          title={
            <Flex align="center" gap="small">
              <FerrisWheel style={{ color: "#FF6347" }} />
              Activities
            </Flex>
          }
          onClick={() => navigate("/tools/activity/new")}
        >
          <Typography.Text>
            Create an activity for your students
          </Typography.Text>
        </StyledCard>
        <StyledCard
          title={
            <Flex align="center" gap="small">
              <Book style={{ color: "#4682B4" }} />
              Story Time
            </Flex>
          }
          onClick={() => navigate("/tools/story/new")}
        >
          <Typography.Text>
            Create a custom story for your students
          </Typography.Text>
        </StyledCard>
        <StyledCard
          title={
            <Flex align="center" gap="small">
              <AudioLines style={{ color: "#32CD32" }} />
              Music
            </Flex>
          }
          onClick={() => navigate("/tools/song/new")}
        >
          <Typography.Text>
            Write a song for your students to sing in any tune
          </Typography.Text>
        </StyledCard>
      </Flex>
    </Container>
  );
};

export default NewToolListing;
