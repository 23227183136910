/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { message } from "antd";
import TextInterface from "./text-interface";
import { useAuth } from "../../../context/auth-context";
import {
  ChatSessionCreateRequest,
  ChatSessionCreateResponse,
  ChatSessionGetHistoryResponse,
  ChatSessionMessage,
  ChatSessionMessageType,
} from "../../../types/chat-session";
import SpinnerComponent from "../../common/spinner";
import { CreateEditTextInterfaceProps } from "./edit";

const CreateTextInterface: React.FC<CreateEditTextInterfaceProps> = ({
  title,
  subtitle,
  type,
  buttonTitle,
  titlePlaceholderText,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { fetchWithToken } = useAuth();
  const [sessionId, setSessionId] = useState<number | undefined>();

  const [chatSessionHistory, setChatSessionHistory] = useState<
    ChatSessionMessage[]
  >([]);

  useEffect(() => {
    const createSession = async () => {
      try {
        const createBody: ChatSessionCreateRequest = {
          type,
        };

        const response = (await fetchWithToken({
          url: `/chat-session`,
          method: "POST",
          body: createBody,
        })) as ChatSessionCreateResponse;

        setSessionId(response.id);

        return response;
      } catch (error) {
        console.error(error);

        messageApi.error({
          content: "Error creating session. Please try refreshing the page",
        });
      }
    };

    createSession();
  }, [fetchWithToken, messageApi, type]);

  const refreshSessionHistory = async () => {
    if (sessionId !== undefined) {
      const sessionWithHistoryResponse = (await fetchWithToken({
        url: `/chat-session/${sessionId}/history`,
        method: "GET",
      })) as ChatSessionGetHistoryResponse;

      setChatSessionHistory(sessionWithHistoryResponse.history);
    }
  };

  if (sessionId === undefined) {
    return <SpinnerComponent />;
  }

  return (
    <>
      {contextHolder}
      <TextInterface
        sessionId={sessionId}
        title={title}
        subtitle={subtitle}
        type={type}
        buttonTitle={buttonTitle}
        titlePlaceholderText={titlePlaceholderText}
        sessionMessageHistory={chatSessionHistory}
        handleRefreshSessionHistory={refreshSessionHistory}
      />
    </>
  );
};

export default CreateTextInterface;
