/** @jsxImportSource @emotion/react */
import { useState, KeyboardEvent, useEffect } from "react";
import { Flex, Input, Typography, Button, message, Space, Card } from "antd";
import styled from "styled-components";
import {
  ChatSessionType,
  ChatSessionMessage,
  ChatSessionGetHistoryResponse,
  ChatSessionMessageType,
  ChatSessionCreateRequest,
  ChatSessionCreateResponse,
} from "../../types/chat-session";
import { useAuth } from "../../context/auth-context";
import { ChatRequest } from "../../types/chat";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { css } from "@emotion/react";

const Container = styled.div`
  margin: 0 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow: hidden;
`;

const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  border-radius: 8px;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

const MessageContainer = styled.div``;

const InputContainer = styled.div`
  padding: 16px;
  border-top: 1px solid #ccc;
  height: 80px;
`;

const SunnyChat = () => {
  const { fetchWithToken } = useAuth();
  const [chatSessionMessages, setChatSessionMessages] = useState<
    ChatSessionMessage[]
  >([
    {
      type: ChatSessionMessageType.ASSISTANT,
      message:
        "Ask me any pre-K related questions, and I'll do my best to help!",
    },
  ]);
  const [requestText, setRequestText] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [sessionId, setSessionId] = useState<number | undefined>();
  const [messageApi, contextHolder] = message.useMessage();
  const [lastResponseText, setLastResponseText] = useState("");

  useEffect(() => {
    const createSession = async () => {
      try {
        const createBody: ChatSessionCreateRequest = {
          type: ChatSessionType.CHAT,
        };

        const response = (await fetchWithToken({
          url: `/chat-session`,
          method: "POST",
          body: createBody,
        })) as ChatSessionCreateResponse;

        setSessionId(response.id);

        return response;
      } catch (error) {
        console.error(error);

        messageApi.error({
          content: "Error creating session. Please try refreshing the page",
        });
      }
    };

    createSession();
  }, [fetchWithToken, messageApi]);

  const handleRefreshSessionHistory = async () => {
    if (sessionId !== undefined) {
      const sessionWithHistoryResponse = (await fetchWithToken({
        url: `/chat-session/${sessionId}/history`,
        method: "GET",
      })) as ChatSessionGetHistoryResponse;

      setChatSessionMessages(sessionWithHistoryResponse.history);
    }
  };

  const handleSendMessage = async () => {
    try {
      if (!sessionId) {
        messageApi.error({
          content: "No session found. Please refresh the page",
        });
        return;
      }

      messageApi.open({
        type: "loading",
        content: "Working our magic...",
        key: "loadingMessage",
        duration: 0,
      });

      const requestBody: ChatRequest = {
        requestText,
        sessionId,
      };

      const response = await fetchWithToken({
        url: `/chat`,
        method: "POST",
        body: requestBody,
        stream: true,
      });

      if (response.body) {
        const reader = response.body.getReader();
        let chunks = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          chunks += new TextDecoder("utf-8").decode(value);
          setLastResponseText(chunks);
        }
      }

      setRequestText("");
      handleRefreshSessionHistory();
    } catch (error) {
      messageApi.error({ content: "Error sending message" });
      console.error(error);
    }

    setRequestLoading(false);
    setRequestText("");

    messageApi.destroy("loadingMessage");
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Container>
      {contextHolder}
      <ChatMessages>
        {chatSessionMessages.map((message, index) => (
          <Card>
            <div key={index}>
              <strong>
                {message.type === ChatSessionMessageType.USER ? "You" : "Sunny"}
                :
              </strong>{" "}
              <div
                css={css`
                  h3 {
                    font-size: 1.17em;
                  }
                `}
              >
                <Markdown remarkPlugins={[remarkGfm]}>
                  {message.message}
                </Markdown>
              </div>
            </div>
          </Card>
        ))}
      </ChatMessages>
      <InputContainer>
        <Space.Compact style={{ width: "100%" }}>
          <Input
            placeholder="What would you like to know?"
            value={requestText}
            onChange={(e) => setRequestText(e.currentTarget.value)}
            onKeyDown={handleKeyPress}
          />
          <Button type="primary" onClick={handleSendMessage}>
            Send
          </Button>
        </Space.Compact>
      </InputContainer>
    </Container>
  );
};

export default SunnyChat;
