/** @jsxImportSource @emotion/react */
import styled from "styled-components";
import { Flex } from "antd";
import { Palette } from "lucide-react";
const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

interface TagProps {
  selected: boolean;
  color: string;
  hoverColor: string;
  textColor: string;
}

const Tag = styled.button<TagProps>`
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid #e5e7eb;
  background-color: ${(props) => (props.selected ? props.color : "white")};
  color: ${(props) => (props.selected ? props.textColor : "inherit")};

  &:hover {
    background-color: ${(props) =>
      props.selected ? props.color : props.hoverColor};
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 5px;
`;

interface ActivityTagsProps {
  selected: string[];
  onSelect: (selected: string[]) => void;
}

export function ActivityTags({ selected, onSelect }: ActivityTagsProps) {
  const tags = [
    {
      id: "math",
      label: "Math",
      color: "#dbeafe",
      hoverColor: "#eff6ff",
      textColor: "#1e40af",
    },
    {
      id: "literacy",
      label: "Literacy",
      color: "#dcfce7",
      hoverColor: "#f0fdf4",
      textColor: "#166534",
    },
    {
      id: "art",
      label: "Art & Craft",
      color: "#f3e8ff",
      hoverColor: "#faf5ff",
      textColor: "#6b21a8",
    },
    {
      id: "motor",
      label: "Motor Skills",
      color: "#ffedd5",
      hoverColor: "#fff7ed",
      textColor: "#9a3412",
    },
    {
      id: "social",
      label: "Social",
      color: "#fce7f3",
      hoverColor: "#fdf2f8",
      textColor: "#9d174d",
    },
    {
      id: "sensory",
      label: "Sensory",
      color: "#ccfbf1",
      hoverColor: "#f0fdfa",
      textColor: "#115e59",
    },
  ];

  const toggleTag = (tagId: string) => {
    if (selected.includes(tagId)) {
      onSelect(selected.filter((id) => id !== tagId));
    } else {
      onSelect([...selected, tagId]);
    }
  };

  return (
    <Flex vertical gap={8}>
      <Title>
        <Palette style={{ color: "#a855f7" }} />
        Activity Focus Areas
      </Title>

      <TagsContainer>
        {tags.map((tag) => (
          <Tag
            key={tag.id}
            selected={selected.includes(tag.id)}
            color={tag.color}
            hoverColor={tag.hoverColor}
            textColor={tag.textColor}
            onClick={() => toggleTag(tag.id)}
          >
            {tag.label}
          </Tag>
        ))}
      </TagsContainer>
    </Flex>
  );
}
