/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Button, Typography, Flex, Card, Image, Modal, Empty } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ChatSessionType } from "../../types/chat-session";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import {
  activityPublishedContentState,
  allPublishedContentState,
  coloringSheetPublishedContentState,
  imagePublishedContentState,
  songPublishedContentState,
  storyPublishedContentState,
} from "../../states/published-content";
import { PublishedContent } from "../../types/published-content";
import {
  BUTTON_TITLE_BY_TYPE,
  LIST_PAGE_SUBTITLE_BY_TYPE,
  LIST_PAGE_TITLE_BY_TYPE,
} from "./util";
import ViewPublishedContent from "./view-published-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileLines,
  faMessage,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import {
  AudioLines,
  Book,
  FerrisWheel,
  Palette,
  Image as ImageIcon,
} from "lucide-react";

const { Title, Text } = Typography;

const Container = styled.div`
  margin: 24px 32px 0px;
`;

const HeaderContainer = styled(Flex)`
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  width: calc(33% - 20px);

  .ant-card-body {
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledCardTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #333;
`;

const SunnyChatButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const SunnyChatButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: linear-gradient(90deg, #ff5f6d 0%, #ffc371 50%, #ff5f6d 100%);
  background-size: 200% auto;
  position: relative;
  overflow: hidden;
  cursor: ${(props) =>
    props.loading || props.disabled ? "not-allowed" : "pointer"};
  opacity: ${(props) => (props.loading || props.disabled ? 0.7 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover:not(:disabled) {
    background: linear-gradient(
      90deg,
      #ff5f6d 100%,
      #ffc371 50%,
      #ff5f6d 0%
    ) !important;
  }
`;

const PublishedContentList = () => {
  const navigate = useNavigate();
  const allPublishedContent = useRecoilValue(allPublishedContentState);

  const refreshPublishedContent = useRecoilRefresher_UNSTABLE(
    allPublishedContentState
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState<
    string | undefined
  >();
  const [selectedContentType, setSelectedContentType] = useState<
    ChatSessionType | undefined
  >();

  useEffect(() => {
    refreshPublishedContent();
  }, [refreshPublishedContent]);

  const showModal = (contentId: string, type: ChatSessionType) => {
    setSelectedContentId(contentId);
    setSelectedContentType(type);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedContentId(undefined);
  };

  return (
    <Container>
      <HeaderContainer justify="space-between" align="center">
        <Title level={3}>Your Library</Title>
        <Flex align="center" gap="small">
          <SunnyChatButton
            type="primary"
            onClick={() => navigate(`/tools/chat`)}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "translateY(-2px)")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.transform = "translateY(0)")
            }
          >
            Chat With Sunny
          </SunnyChatButton>
          <Button
            type="primary"
            onClick={() => navigate(`/tools/new`)}
            style={{
              background: "linear-gradient(180deg, #7a21db 0%, #5a3fdc 100%)",
              border: "none",
              transition: "transform 0.3s ease",
            }}
            icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "translateY(-2px)")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.transform = "translateY(0)")
            }
          >
            Create Something New
          </Button>
        </Flex>
      </HeaderContainer>
      <ListContainer>
        {allPublishedContent.length === 0 && (
          <Empty
            description="There's nothing here yet. Let's change that!"
            style={{ margin: "0 auto", marginTop: "15%" }}
          >
            <Button
              type="primary"
              onClick={() => navigate(`/tools/new`)}
              style={{
                background: "linear-gradient(180deg, #7a21db 0%, #5a3fdc 100%)",
                border: "none",
                transition: "transform 0.3s ease",
              }}
              icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "translateY(-2px)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "translateY(0)")
              }
            >
              Create Something New
            </Button>
          </Empty>
        )}
        {allPublishedContent.map((publishedContent) => (
          <StyledCard
            key={publishedContent.id}
            onClick={() =>
              navigate(
                `/tools/${publishedContent.type
                  .toLowerCase()
                  .replace("_", "-")}/${publishedContent.id.toString()}/edit`
              )
            }
            hoverable
          >
            <Flex vertical gap="9px" style={{ height: "100%" }}>
              <Flex justify="space-between" align="center">
                <Flex align="center" gap="small">
                  {publishedContent.type === ChatSessionType.ACTIVITY && (
                    <FerrisWheel style={{ color: "#FF6347" }} /> // Tomato
                  )}
                  {publishedContent.type === ChatSessionType.STORY && (
                    <Book style={{ color: "#4682B4" }} /> // Steel Blue
                  )}
                  {publishedContent.type === ChatSessionType.SONG && (
                    <AudioLines style={{ color: "#32CD32" }} /> // Lime Green
                  )}
                  {publishedContent.type === ChatSessionType.COLORING_SHEET && (
                    <Palette style={{ color: "#87CEEB" }} /> // Sky Blue
                  )}
                  {publishedContent.type === ChatSessionType.IMAGE && (
                    <ImageIcon style={{ color: "#FFB6C1" }} /> // Light Pink
                  )}
                  <Text type="secondary">
                    {BUTTON_TITLE_BY_TYPE[publishedContent.type]}
                  </Text>
                </Flex>
              </Flex>
              <Flex vertical gap="6px">
                <StyledCardTitle>{publishedContent.title}</StyledCardTitle>
                {publishedContent.attachmentUrl && (
                  <Image
                    alt={publishedContent.title}
                    src={publishedContent.attachmentUrl}
                    preview={false}
                    style={{
                      objectFit: "contain",
                      borderRadius: "8px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      maxHeight: "150px",
                      width: "auto",
                    }}
                  />
                )}
                <Typography.Paragraph ellipsis={{ rows: 3 }}>
                  {publishedContent.summary}
                </Typography.Paragraph>
              </Flex>
              <Text type="secondary" style={{ marginTop: "auto" }}>
                {publishedContent.updatedAt !== publishedContent.createdAt
                  ? `Updated ${new Date(
                      publishedContent.updatedAt
                    ).toLocaleString()}`
                  : `Added ${new Date(
                      publishedContent.createdAt
                    ).toLocaleString()}`}
              </Text>
            </Flex>
          </StyledCard>
        ))}
      </ListContainer>
    </Container>
  );
};

export default PublishedContentList;
